var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"color1 color1Text--text","flat":""}},[_c('v-toolbar-title',[_vm._v("Product Manager")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"color3Text color3--text","fab":"","small":"","to":"__new__","disabled":_vm.extLoading}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-plus")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.productObj,"options":_vm.pagination,"item-key":"id","loading":_vm.loading || _vm.extLoading,"search":_vm.search,"show-select":_vm.doDelete},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"slot":"progress","color":"color3","indeterminate":""},slot:"progress"})]},proxy:true},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"subheading"},[_vm._v(_vm._s(item.code))]),_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status === 'Active' ? 'success white--text' : 'error white--text'}},[_vm._v(" "+_vm._s(item.status)+" "),(item.auto)?_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("fas fa-magic")]):_vm._e()],1)]}},{key:"item.maxUses",fn:function(ref){
var item = ref.item;
return [(item.maxUses)?_c('span',[_vm._v(_vm._s(item.maxUses))]):_c('v-icon',[_vm._v("fas fa-infinity")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"color3 color3Text--text","small":"","fab":"","to":("" + (item.urlTag))}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-pencil")])],1),_c('v-btn',{attrs:{"color":"color3 color3Text--text","small":"","fab":""},on:{"click":function($event){return _vm.open(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-external-link")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-container',{staticClass:"pa-0"},[_c('v-expand-transition',[(_vm.selected.length)?_c('div',[_c('v-row',{staticClass:"px-4",attrs:{"dense":""}},[_c('v-col',{staticClass:"subheading",attrs:{"cols":"12"}},[_vm._v(" What would you like to do with the selected products? ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success white--text"},on:{"click":function($event){$event.stopPropagation();return _vm.enableSelected($event)}}},[_vm._v("Enable")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error white--text"},on:{"click":function($event){$event.stopPropagation();return _vm.disableSelected($event)}}},[_vm._v("Disable")]),_c('v-btn',{attrs:{"color":"white error--text"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteSelected($event)}}},[_vm._v("Delete")])],1)],1)],1):_vm._e()]),_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('v-btn',{staticClass:"xsmall",attrs:{"color":"color3","text":"","icon":"","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('sync-data')}}},[_c('v-icon',[_vm._v("fas fa-redo-alt")])],1)],1)],1),_c('vbl-confirm-dialog',{attrs:{"ask":_vm.confirmDialog,"loading":_vm.loading},on:{"confirm":function () { if(_vm.confirmAction) { _vm.confirmAction() } },"deny":function($event){_vm.confirmDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }