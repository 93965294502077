<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text" flat>
      <v-toolbar-title>Product Manager</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="color3Text color3--text" fab small to="__new__" :disabled="extLoading">
        <v-icon small>fas fa-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="productObj"
      :options.sync="pagination"
      item-key="id"
      :loading="loading || extLoading"
      :search="search"
      :show-select="doDelete"
      v-model="selected"
    >
      <template v-slot:progress>
        <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
      </template>
      <template v-slot:[`item.code`]="{ item }">
        <div class="subheading">{{item.code}}</div>
        {{item.description}}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status === 'Active' ? 'success white--text' : 'error white--text'">
          {{item.status}}
          <v-icon v-if="item.auto" small class="ml-2">fas fa-magic</v-icon>
        </v-chip>
      </template>
      <template v-slot:[`item.maxUses`]="{ item }">
        <span v-if="item.maxUses">{{item.maxUses}}</span>
        <v-icon v-else>fas fa-infinity</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="color3 color3Text--text"
          small fab
          :to="`${item.urlTag}`"
        >
          <v-icon small>fas fa-pencil</v-icon>
        </v-btn>
        <v-btn
          color="color3 color3Text--text"
          small fab
          @click="open(item)"
        >
          <v-icon small>fas fa-external-link</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-container class="pa-0">
      <v-expand-transition>
        <div v-if="selected.length">
          <v-row dense class="px-4">
            <v-col cols="12" class="subheading">
              What would you like to do with the selected products?
            </v-col>
            <v-col cols="12">
              <v-btn
                color="success white--text"
                class="mr-2"
                @click.stop="enableSelected"
              >Enable</v-btn>
              <v-btn
                color="error white--text"
                class="mr-2"
                @click.stop="disableSelected"
              >Disable</v-btn>
              <v-btn
                color="white error--text"
                @click.stop="deleteSelected"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
      <v-row dense justify="end">
        <v-btn color="color3" text class="xsmall" icon @click.stop="$emit('sync-data')" :loading="loading">
          <v-icon>fas fa-redo-alt</v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <!-- Confirm-->
    <vbl-confirm-dialog
      :ask="confirmDialog"
      @confirm="() => { if(confirmAction) confirmAction() }"
      @deny="confirmDialog = false"
      :loading="loading"
    ></vbl-confirm-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Product from '@/classes/Product'

export default {
  props: ['active', 'username', 'products', 'extLoading'],
  data () {
    return {
      loading: false,
      search: null,
      headers: [
        { text: 'Id', value: 'id', align: 'left' },
        { text: 'Title', value: 'title', align: 'left' },
        { text: 'Status', value: 'status', align: 'left' },
        { text: '', value: 'actions', align: 'center', sortable: false }
      ],
      persistDialog: false,
      selectedProduct: null,
      pagination: { itemsPerPage: 10 },
      selected: [],
      doDelete: true,
      confirmDialog: false,
      confirmAction: null
    }
  },
  computed: {
    ...mapGetters(['user', 'getPageInfo', 'theme']),
    productObj () {
      return this.products.map(m => new Product(m))
    },
    organization () {
      return this.getPageInfo(this.username)
    }
  },
  methods: {
    select (product) {
      this.selectedProduct = product
      this.$emit('product-selected', product)
      this.$refs.detailDrawer.open()
    },
    open (product) {
      const subdomain = window.location.host.startsWith(this.theme.username) ? '' : this.username + '.'
      const base = `${window.location.protocol}//${subdomain}${window.location.host}`
      window.open(`${base}/store/${product.urlTag}`, '_blank')
    },
    newProduct () {
      console.log(this.organization)
      this.select(new Product({ organizationId: this.organization.id }))
    },
    onSaveComplete (dto) {
      var e = this.productObj.find(f => f.id === dto.id)
      if (e) {
        e.update(dto)
      } else {
        this.products.push(dto)
      }
    },
    deleteSelected () {
      this.confirmAction = this.performDelete
      this.confirmDialog = true
    },
    disableSelected () {
      this.confirmAction = this.performDisable
      this.confirmDialog = true
    },
    enableSelected () {
      this.confirmAction = this.performEnable
      this.confirmDialog = true
    },
    performEnable () {
      this.loading = true
      const ids = this.selected.map(m => m.id)
      this.$VBL.store.products.enable(this.username, ids)
        .then(r => {
          this.selected.forEach(f => {
            f.published = true
          })
          this.selected = []
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.confirmAction = null
          this.confirmDialog = false
        })
    },
    performDisable () {
      this.loading = true
      const ids = this.selected.map(m => m.id)
      this.$VBL.store.products.disable(this.username, ids)
        .then(r => {
          this.selected.forEach(f => {
            f.published = false
          })
          this.selected = []
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.confirmAction = null
          this.confirmDialog = false
        })
    },
    performDelete () {
      this.loading = true
      const ids = this.selected.map(m => m.id)
      this.$VBL.store.products.delete(this.username, ids)
        .then(r => {
          this.products = this.products.filter(f => !ids.includes(f.id))
          this.selected = []
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.confirmAction = null
          this.confirmDialog = false
        })
    },
    onAdvancedClick () {
      this.$emit('advanced-click')
    }
  },
  components: {
  }
}
</script>

<style>

</style>
